import API_CALL, { getResponse } from './API';
import { Channel, ChannelStatus, BriefSpace, PusblishStatus as PublishStatus, ChannelAnalitic, Tags } from 'store/types';
import { sortBy, times, random, sumBy } from 'lodash';
import { Moment } from 'constants/date.helpers';
import { numberFormatter } from 'constants/helpers';

const url = 'channels/';
export const ServiceChannels = {
  business: {
    list: async function ({ headers = {} } = {}) {
      return API_CALL({
        url: '/business/channels',
        headers
      }).then((response: { space: BriefSpace; channels: Channel[] }) => {
        response.channels = sortBy(response.channels, ['position']);
        return response;
      });
    },

    get: async function ({ channelUUID = undefined, headers = {} }) {
      return API_CALL({
        url: '/business/channels/' + channelUUID,
        params: {
          expand: 'author,briefs'
        },
        headers
      }).then((response) => {
        const channel = getResponse<Channel>(response, 'channel');
        return channel;
      });
    }
  },

  channels: async ({ headers = {} }) => {
    return API_CALL({
      url: `business/channels?expand=author,channels`,
      method: 'GET',
      headers
    }).then((response: { space: BriefSpace; channels: Channel[] }) => {
      response.channels = sortBy(response.channels, ['position']);
      return response;
    });
  },

  home: async ({ headers = {} }) => {
    return API_CALL({
      url: `business/home?expand=author,channels`,
      method: 'GET',
      headers
    }).then((response: { space: BriefSpace; channels: Channel[] }) => {
      response.channels = sortBy(response.channels, ['position']);
      return response;
    });
  },
  /**
   *
   * @param param0 Public chanel list
   * @returns
   */
  list: async function ({ userUUID = undefined, headers = {} }) {
    return API_CALL({
      url: '/business/users/' + userUUID,
      headers
    }).then((response) => {
      const channels = getResponse<Channel[]>(response, 'channels');
      return channels;
    });
  },

  get: async function ({ channelUUID, headers = {} }) {
    return API_CALL({
      url: `/business/channels/${channelUUID}?expand=author,briefs`,
      params: {
        expand: 'author,briefs'
      },
      headers
    }).then((response) => {
      const channel = getResponse<Channel>(response, 'channel');
      return channel;
    });
  },

  create: async ({ headers = {} }) => {
    return API_CALL({
      url: `/business/channels/create?expand=spaces`,
      method: 'POST',
      headers
    }).then((response) => {
      const channelCreated = getResponse<Channel>(response, 'channel');
      return channelCreated;
    });
  },

  update: async ({
    channelUUID,
    channel,
    headers = {}
  }: {
    channelUUID: string;
    channel: Channel;
    headers?: any;
  }) => {
    return API_CALL({
      url: `business/channels/update/${channelUUID}`,
      method: 'PUT',
      headers,
      data: channel
    }).then((response) => {
      const channelCreated = getResponse<Channel>(response, 'channel');
      return channelCreated;
    });
  },

  delete: async ({ channelUUID, headers = {} }: { channelUUID: string; headers?: any }) => {
    return API_CALL({
      url: `business/channels/${channelUUID}`,
      method: 'DELETE',
      headers
    }).then((response) => {
      const channelCreated = getResponse<Channel>(response, 'channel');
      return channelCreated;
    });
  },

  order: async ({ channelUUID, position }: { channelUUID: string; position: number }) => {
    return API_CALL({
      //baseURL : API_URL + url + "loginCredentials"
      url: `business/channels/${channelUUID}/order`,
      method: 'POST',
      data: {
        position
      }
    });
  },

  status: async ({ channelUUID, status }: { channelUUID: string; status: PublishStatus }) => {
    const published = status === PublishStatus.Online ? 'on' : 'off';
    return API_CALL({
      url: `business/channels/${channelUUID}/publish/${published}`,
      method: 'POST'
    }).then((response) => {
      const channelCreated = getResponse<Channel>(response, 'channel');
      return channelCreated;
    });
  },

  coeditors: async ({ channelUUID }: { channelUUID: string }) => {
    return API_CALL({
      url: `business/channels/${channelUUID}/coeditors`
    }).then((response) => {
      const coeditors = getResponse<any[]>(response, 'coeditors');
      return coeditors;
    });
  },

  invite: async ({ channelUUID, email }: { channelUUID: string; email: string }) => {
    return API_CALL({
      url: `business/channels/${channelUUID}/invite`,
      method: 'POST',
      data: { email }
    }).then((response) => {
      //const channelCreated = getResponse<Channel>(response, 'channel');
      return response;
    });
  },

  inviteDelete: async ({ channelUUID, email }: { channelUUID: string; email: string }) => {
    return API_CALL({
      url: `business/channels/${channelUUID}/invite/delete`,
      method: 'POST',
      data: { email }
    }).then((response) => {
      //const channelCreated = getResponse<Channel>(response, 'channel');
      return response;
    });
  },

  analytic: async (channelUUID: string): Promise<ChannelAnalitic> => {

    return API_CALL({
      url: `business/channels/${channelUUID}/analytics`
    }).then((response) => {
      //const channelCreated = getResponse<Channel>(response, 'channel');

      const formattedAnalitics = formatAnalitics(response)
      console.log("🚀 ~ analytic: ~ formattedAnalitics", formattedAnalitics)
      debugger;
      return formattedAnalitics
    })
  },

  addtTags: async (channelUUID: string, tags: string[]): Promise<ChannelAnalitic> => {

    return API_CALL({
      url: `business/channels/${channelUUID}/tags`,
      method: "POST",
      data: { tags }
    }).then((response) => {

      return response
    })
  },

  searchTags: async (search: string): Promise<string[]> => {

    return API_CALL({
      url: `business/tags?s=${search}`
    }).then((response) => {
      const tags = getResponse<Tags[]>(response, 'tags');

      return tags.map(tags => tags.name);
    })
  }

};
export default ServiceChannels;


const formatAnalitics = (response: ChannelAnalitic) => {

  response.days = response.days.map(day => {

    day.timestamp = Moment.unix(day.timestamp)
    //console.log(day.timestamp.format())

    return day;

  })

  response.count = numberFormatter(response.count);
  return response

}
export const getStubAnalitics = () => {

  const stubAnalitic: ChannelAnalitic = {

    count: random(0, 2500),
    days: times(30, (x) => {
      return {
        "timestamp": Moment().startOf("month").add(x, "d"),
        "count": random(0, 2500),
      }
    })
  }

  stubAnalitic.count = numberFormatter(sumBy(stubAnalitic.days, "count"));

  return stubAnalitic;
}
