import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');
export var Moment = moment;
export var defaultDateFormat = 'DD-MM-YYYY';
export var getMonths = function getMonths() {
  var _short = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  return _short ? moment.monthsShort() : moment.months();
};
export var isBefore = function isBefore(creationDate) {
  var diff = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 6;
  var dat = moment(creationDate, 'X').add(diff, 'hours'); //console.log(dat.format("DD-MM-YY HH:mm:ss"))

  var now = moment(); //console.log(now.format("DD-MM-YY HH:mm:ss"))

  return now.isBefore(dat);
};
export var DateNow = function DateNow() {
  var dateFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultDateFormat;
  return moment().format(dateFormat).toString();
};
export var FormatDate = function FormatDate(dateString) {
  var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultDateFormat;
  return moment(dateString).format(dateFormat);
};
export var FormatSendDate = function FormatSendDate(dateString) {
  // 2017-02-04
  return moment(dateString).format('YYYY-MM-DD HH:mm');
};
export var formatTimeStamp = function formatTimeStamp(timestamp) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultDateFormat;
  return moment.unix(timestamp).format(format);
};
export var getAge = function getAge(birthday) {
  var inputDateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
  return moment().diff(moment(birthday, inputDateFormat), 'years');
};
export var formatDatesInPayload = function formatDatesInPayload(payload) {
  return _.mapValues(payload, function (data) {
    if (moment.isMoment(data) || moment.isDate(data)) {
      data = moment(data).format('YYYY-MM-DD');
    }

    return data;
  });
};
export var NOW = function NOW() {
  return Moment();
};
export var getNowTimestamp = function getNowTimestamp() {
  return moment().unix();
};