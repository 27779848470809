export const COLORS = {
  main: '#FD3909',


  black: "#414141",
  darkGray: "#646672",
  lightGray: "#9fa5af",
  icon: "#9fa5af",

  iconHover: "#646672",
  textColor: "#414141",
  iconActive: "#505469",


  bgs: {
    red: '#D03939',

    orange: '#F0550F',

    lightBlue: '#39A7D0',

    violet: '#8F56F1',

    green: '#39D045',

    lightGreen: '#39D0AD',

    purple: '#CB4BD6',

    yellow: '#D0CA39',

    strongGreen: '#85D039',

    dark: '#6C6C6C',

    bluette: '#4F60E3',

    brown: '#D09C39'
  }
};

export default COLORS;
